<template>
  <div class="cw-confirmation-dialog--container">
    <v-dialog
      v-model="show"
      :max-width="600"
      content-class="cw-confirmation-dialog"
      persistent
    >
      <component
        :is="dialogComponent"
        v-if="component"
        v-bind="{ ...props }"
        @dialog-cancel="closeDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'CwConfirmationDialog',

  data: () => ({
    component: null,
    folder: null,
    props: {},
    show: false,
  }),

  computed: {
    dialogComponent() {
      if (!this.component) return null;

      const dialogImports = {
        fi: () => import('@fi/components/dialogs/' + this.component), // eslint-disable-line
        shared: () => import('@shared/components/dialogs/' + this.component), // eslint-disable-line
      };

      return dialogImports[this.folder];
    },
  },

  watch: {
    dialogComponent: {
      handler() {
        this.$wait.end('confirmation_dialog__confirm_processing');
      },
      immediate: true,
    },
  },

  mounted() {
    this.$bus.$on('OPEN_CONFIRMATION_DIALOG', ({
      component,
      folder = 'shared',
      props = {},
    } = {}) => {
      // open dialog
      this.component = component;
      this.folder = folder;
      this.props = props;
      this.show = true;
    });
    // close dialog
    this.$bus.$on('CLOSE_CONFIRMATION_DIALOG', () => {
      this.show = false;
    });
  },
  methods: {
    closeDialog() {
      this.$bus.$emit('CLOSE_CONFIRMATION_DIALOG');
    },
  },
};
</script>

<style lang="scss">
  .cw-confirmation-dialog {
    margin: 10px;

    ul {
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        padding: 8px 8px 8px 24px;

        &::before {
          color: currentColor;
          content: '\E5CA';
          font-family: Material Icons, sans-serif;
          font-weight: normal;
          font-style: normal;
          font-size: 1rem;
          line-height: 1;
          height: inherit;
          min-height: 1rem;
          min-width: 1rem;
          position: absolute;
          top: 11px;
          margin-right: 1rem;
          left: 0;
          width: 1rem;
        }
      }
    }
  }
</style>
